import React, { useEffect, useState } from 'react';
import OtherNews from '../components/OtherNews';
import { InfinitySpin } from 'react-loader-spinner';
import {
  getArticles,
  getArticlesByCategory,
  getCategories,
} from '../service/api';
import { useParams } from 'react-router-dom';
import MajorNews from '../components/MajorNews';
import MainNews from '../components/MainNews';
import NewsSlider from '../components/slider/NewsSlider';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CATEGORIES } from '../redux/actions/appActions';

const Home = () => {
  const [articles, setArticles] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  //get category from url
  const { category } = useParams();
  const categories = useSelector((state: any) => state.app.categories);

  useEffect(() => {
    fetchCategories();
    if (category === undefined) fetchArticles();
    fetchArticlesByCategory();
  }, [category]);

  const fetchCategories = async () => {
    const cat = await getCategories();
    dispatch({ type: SET_CATEGORIES, payload: cat });
  };

  const fetchArticles = async () => {
    const art = await getArticles(15);
    setArticles(art);
    setLoading(false);
  };

  const fetchArticlesByCategory = async () => {
    if (category === undefined) return;
    const art = await getArticlesByCategory(category, 15);
    setArticles(art);
    setLoading(false);
  };

  return (
    <div className="max-w-screen-xl py-4 mx-auto space-y-16 p-4 xl:p-0 dark:bg-gray-900">
      {loading ? (
        <div className="justify-center flex align-middle h-screen items-center">
          <InfinitySpin width="200" color="#35245b" />
        </div>
      ) : articles && articles.length > 0 ? (
        <>
          <NewsSlider articles={articles.slice(0, 6)} />
          <MajorNews articles={articles.slice(0, 3)} />
          <MainNews articles={articles.slice(3, 6)} />
          <OtherNews articles={articles.slice(6, 14)} />
        </>
      ) : (
        <div className="justify-center flex h-screen items-center">
          <h2 className="text-3xl font-bold text-custom-400 dark:text-white ">
            No se encontraron noticias
          </h2>
        </div>
      )}
    </div>
  );
};

export default Home;
