import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Article } from '../models/Article';
import parse from 'html-react-parser';
import ShowCat from './categories/ShowCat';
interface Props {
  article: Article;
}
const PreviewLarge = (props: Props) => {
  const { article } = props;
  const navigate = useNavigate();
  return (
    <div
      className="rounded-lg bg-custom-200 shadow flex-col flex 
      bg-cover bg-center bg-no-repeat justify-between w-full h-80
      dark:bg-gray-800 dark:border-gray-700 border-2 border-custom-100
      transition duration-100 ease-in-out transform overflow-hidden
      hover:ring-2  hover:ring-custom-300
      hover:z-10 hover:opacity-100 hover:cursor-pointer"
      style={{
        backgroundImage: `url("${article?.cover}")`,
      }}
      onClick={() => {
        navigate(`/articles/${article.id}`);
      }}
    >
      <ShowCat categories={article.categories} />
      <div
        className="p-4 space-between flex flex-col bg-opacity-90 rounded-b-md bg-custom-100 
        dark:bg-gray-800 dark:border-gray-700 
          justify-between h-2/5"
      >
        <div className="flex flex-col">
          <span>
            <h5 className="text-white font-bold text-xl tracking-tight mb-2 ">
              {article?.title}
            </h5>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreviewLarge;
