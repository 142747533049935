import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { getCategories } from '../service/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [categories, setCategories] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getCategories();
      setCategories(categories);
    };
    fetchCategories();
    setDate(new Date());
  }, []);

  return (
    <div className={`dark:bg-gray-900 ${darkMode ? 'dark' : ''}`}>
      <div className={`sticky top-0 bg-custom-50 dark:bg-gray-800 `}>
        <div className="w-full h-6 bg-custom-100 dark:bg-gray-900 text-center">
          <div className="text-white text-sm">
            {date.toLocaleDateString('es-ES', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }) +
              ' • Actualizado en ' +
              date.toLocaleTimeString('it-IT', {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </div>
        </div>
        <div className="antialiased bg-custom-50 dark:text-gray-200 dark:bg-gray-800 z-10 rounded-b-lg sticky top-0">
          <div className="w-full text-custom-100 drop-shadow-lg p-3 xl:px-0 bg-custom-50 dark:bg-gray-800 rounded-b-lg">
            <div className="flex flex-col max-w-screen-xl mx-auto md:items-center md:flex-row">
              <div className="flex flex-row items-center">
                <Link
                  to={'/'}
                  className="w-full text-lg font-semibold tracking-widest text-custom-100 uppercase rounded-lg dark:text-white focus:outline-none focus:shadow-outline"
                >
                  <img
                    src="/logo.png"
                    alt="Logo"
                    className="w-full md:w-40 text-white dark:bg-transparent "
                  />
                </Link>
              </div>
              <nav className="flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-end md:flex-row">
                {/* <Link
                  className="px-4 py-2 mt-2 text-md font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 focus:text-gray-900 hover:bg-custom-100 hover:bg-opacity-10"
                  to={'/'}
                >
                  Home
                </Link> */}
                {/* <div className="relative">
                  <button
                    className="flex flex-row text-custom-100 items-center w-full px-4 py-2 mt-2 text-md font-semibold text-left bg-transparent rounded-lg dark:text-gray-200
                   dark:bg-transparent dark:focus:text-white dark:hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span>Categories</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1
                     md:ml-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                  {isOpen && (
                    <div className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                      <div className="px-2 py-2 bg-white rounded-md shadow dark:bg-gray-800">
                        {categories.map((category: any) => (
                          <Link
                            key={category.id}
                            className="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:bg-gray-700 dark:focus:text-white"
                            to={`/categories/${category.id}`}
                          >
                            {category.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div> */}
              </nav>
              <div className="justify-center flex flex-row items-center">
                <button className="" onClick={() => setDarkMode(!darkMode)}>
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    size="1x"
                    className="text-custom-100 cursor-pointer hover:text-custom-300 p-2 m-2 rounded-full
                    dark:text-gray-200 dark:hover:text-white"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Announcements */}
        {/* <div className="bg-white dark:bg-gray-800">
        <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-custom-100">
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="ml-3 font-medium text-custom-100 truncate">
                <span className="md:hidden">We announced a new product!</span>
                <span className="hidden md:inline">
                  Big news! We re excited to announce a brand new product.
                </span>
              </p>

              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  <a
                    href="#"
                    className="flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-100 hover:bg-custom-100 focus:outline-none focus:border-custom-100 focus:shadow-outline-custom active:bg-custom-100 transition ease-in-out duration-150"
                  >
                    View all
                  </a>

                  <a
                    href="#"
                    className="flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-custom-100 bg-white hover:text-custom-100 hover:bg-gray-50 focus:outline-none focus:border-custom-100 focus:shadow-outline-custom active:bg-gray-50 active:text-custom-100 transition ease-in-out duration-150"
                  >
                    Dismiss
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className="bg-white dark:bg-gray-900 py-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
