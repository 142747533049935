import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Article } from '../models/Article';
import parse from 'html-react-parser';
import ShowCat from './categories/ShowCat';
interface Props {
  article: Article | null;
}
const PreviewHero = (props: Props) => {
  const { article } = props;
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `url("${article?.cover}")`,
      }}
      className={`rounded-lg flex-1 bg-white shadow bg-opacity-10 flex-col flex 
      justify-between bg-cover bg-center bg-no-repeat h-full w-full overflow-hidden
      dark:bg-gray-800 dark:border-gray-700 h-96 xl:h-full
      transition duration-100 ease-in-out transform border-2 border-custom-100
      hover:ring-2 hover:ring-custom-300 hover:opacity-100 hover:cursor-pointer  `}
      onClick={() => navigate('articles/' + article?.id)}
    >
      <ShowCat categories={article?.categories} />
      <div
        className="rounded-b-md bg-custom-100 bg-opacity-90 h-1/3 dark:bg-gray-800 dark:border-gray-700
       "
      >
        <div className="p-4 flex flex-col space-between">
          <div className="flex flex-col">
            <h5 className="font-bold text-2xl tracking-tight mb-2 text-white text-center">
              {article?.title}
            </h5>
            <div
              className="font-normal text-gray-200 mb-3 text-sm whitespace-pre-line hidden md:block
            leading-relaxed tracking-tight dark:text-gray-300 h-20 overflow-hidden"
            >
              {parse(article?.excerpt ?? '')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewHero;
