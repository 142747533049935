import React from 'react';
import { Article } from '../models/Article';
import PreviewHero from './PreviewHero';
import PreviewLarge from './PreviewLarge';

interface Props {
  articles: Article[];
}
const MajorNews = (props: Props) => {
  const { articles } = props;
  return (
    <div className="grid grid-cols-1 xl:grid-cols-4 xl:gap-4 space-y-4 xl:space-y-0">
      <div className="col-span-3">
        <PreviewHero
          article={articles && articles.length > 0 ? articles[0] : null}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4">
        {articles.slice(1, 3).map((article: Article) => {
          return <PreviewLarge key={article?.id} article={article} />;
        })}
      </div>
    </div>
  );
};

export default MajorNews;
