import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Article } from '../../models/Article';
import './NewsSlider.scss';

interface Props {
  articles: Article[];
}
const NewsSlider = (props: Props) => {
  const navigate = useNavigate();
  const { articles } = props;
  const renderSlide = (article: Article, prefix: string) => {
    return (
      <div
        key={prefix + article.id}
        className="slide hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer rounded-lg"
        onClick={() => navigate(`/articles/${article.id}`)}
      >
        <img src={article.cover} className="rounded-lg" />
        <span className="text-custom-100 dark:text-white font-bold text-sm">
          {article.title}
        </span>
      </div>
    );
  };
  return (
    <div className="flex gap-1 w-100 ">
      {/* <div className="bg-custom-100 dark:bg-gray-700 rounded-lg">
        <p
          className="p-2 px-4 text-white font-bold text-sm animate-pulse"
          style={{ writingMode: 'vertical-lr' }}
        >
          Latest
        </p>
      </div> */}
      <div className="slider  border-custom-100 rounded-lg">
        <div className="slide-track">
          {articles.map((article) => renderSlide(article, 'a_'))}
          {articles.map((article) => renderSlide(article, 'b_'))}
        </div>
      </div>
    </div>
  );
};

export default NewsSlider;
