import './App.css';
import Home from './pages/Home';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import ArticlePage from './pages/ArticlePage';
import Navbar from './components/Navbar';
import { Provider } from 'react-redux';
import store from './redux/store';

function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }; // if (window.twttr) {
    //   window.twttr.widgets.load();
    // }
  }, []);
  return (
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="articles">
              <Route path=":articleId" element={<ArticlePage />} />
            </Route>
            <Route path="categories">
              <Route path=":category" element={<Home />} />
            </Route>
          </Route>
        </Routes>
      </HashRouter>
    </Provider>
  );
}

export default App;
