import React from 'react';
import { useSelector } from 'react-redux';
import { Category } from '../../models/Category';
interface Props {
  categories?: Category[];
}
const ShowCat = (props: Props) => {
  const { categories } = props;

  return (
    <div className="flex flex-row gap-1">
      {categories?.map((cat: Category) => {
        return (
          <div
            key={cat.id}
            className={`mt-2 ml-2 text-sm font-medium text-white p-2 dark:text-gray-400 dark:border-green-500 bg-opacity-70
            rounded-lg bg-custom-100 dark:bg-gray-800 dark:border-gray-700`}
          >
            {cat.name}
          </div>
        );
      })}
    </div>
  );
};

export default ShowCat;
