import axios from 'axios';
import { Article } from '../models/Article';
import { Category } from '../models/Category';

// const URL = 'http://localhost:1337/api/';
// const IMAGE_URL = 'http://localhost:1337';
// const API_TOKEN =
//   'aab1ba6e0447ce2a81d8aa78f8a7278e7433b8044fd7489a090f2583285435254092d3f74c763efa845f29aa148db27d536e30fd90632a8a38c11b9958464211ade8c20e43752cf18088a599116f19023330d61e43723df2dd0caec4b666c98ded8c4216987e4adbacc88541123ffa2663582780515f131387e7aff0ef41977b';
// const header = `Authorization: Bearer ${process.env.REACT_APP_API_KEY}`;

export const instance = axios.create({
  // baseURL: 'http://fightclub365.byethost15.com/wp-json/wp/v2/',
  // baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'https://www.fightclub365.news/wp-json/wp/v2/',
  // baseURL: 'https://fightclub365.altervista.org/wp-json/wp/v2/',
  // baseURL: 'https://emmegio89.wordpress.com/wp-json/wp/v2/',
  baseURL: 'http://administration.fightclub365.info/wp-json/wp/v2/',
  // baseURL: 'https://fightclub365.000webhostapp.com/wp-json/wp/v2/',
  // timeout: 2000,
  //   headers: { header: header },
});

export const getArticles = async (size: number) => {
  try {
    const res = await instance.get(`posts?per_page=${size}&_embed`);
    if (res.status === 200) {
      const articles = res.data.map((e: any) => {
        return mapArticle(e);
      });
      return articles;
    } else return [];
  } catch (e) {
    return [];
  }
};

export const getArticlesByCategory = async (category: string, size: number) => {
  try {
    const res = await instance.get(
      // `articles?populate=cover,category,author,blocks&populate=author.avatar&sort=publishedAt:desc&filters[category][name][$eq]=${category}&pagination[pageSize]=${size}`
      `posts?categories=${category}`
    );
    if (res.status === 200) {
      const articles = res.data.map((e: any) => {
        return e;
      });
      return articles;
    } else return [];
  } catch (e) {
    return [];
  }
};

export const getArticle = async (id: string | number) => {
  const res = await instance.get(`posts/${id}?_embed`);
  if (res.status === 200) {
    return mapArticle(res.data);
  }
};

export const getArticlesByAuthor = async (author: string) => {
  const res = await instance.get(
    `articles?populate=cover,category,author,blocks&populate=author.avatar&sort=publishedAt:desc&pagination[pageSize]=15&author.name=${author}`
  );
  if (res.status === 200) {
    const articles = res.data.map((e: any) => {
      return e;
    });
    return articles;
  }
};

export const getCategories = async () => {
  const res = await instance.get(`categories?sort=name:asc`);
  if (res.status === 200) {
    const categories = res.data.map((e: any) => {
      return e;
    });
    return categories;
  }
};

export const getAuthors = async () => {
  const res = await instance.get(`authors?sort=name:asc`);
  if (res.status === 200) {
    const authors = res.data.map((e: any) => {
      return e.attributes.name;
    });
    return authors;
  }
};

// export const getArticlesBySearch = async (search: string) => {
//   const res = await instance.get(
//     `articles?populate=cover,category,author,blocks&populate=author.avatar&sort=publishedAt:desc&pagination[pageSize]=15&title_contains=${search}`
//   );
//   if (res.status === 200) {
//     const articles = res.data.map((e: any) => {
//       return mapArticle(e);
//     });
//     return articles;
//   }
// };

// export const getArticlesByTag = async (tag: string) => {
//   const res = await instance.get(
//     `articles?populate=cover,category,author,blocks&populate=author.avatar&sort=publishedAt:desc&pagination[pageSize]=15&tags.name=${tag}`
//   );
//   if (res.status === 200) {
//     const articles = res.data.map((e: any) => {
//       return mapArticle(e);
//     });
//     return articles;
//   }
// };

// export const getTags = async () => {
//   const res = await instance.get(`tags?sort=name:asc`);
//   if (res.status === 200) {
//     const tags = res.data.map((e: any) => {
//       return e.attributes.name;
//     });
//     return tags;
//   }
// };

const mapArticle = (article: any): Article => {
  return {
    id: article.id,
    title: article.title.rendered,
    slug: article.slug,
    excerpt: article.excerpt.rendered,
    content: article.content.rendered,
    date: article.date,
    modified: article.modified,
    author:
      article?._embedded?.author?.length > 0
        ? article._embedded.author[0]
        : undefined,
    blocks: article.blocks,
    // cover: article.fimg_url,
    categories: article._embedded?.['wp:term']?.[0] || [],
    cover:
      article._embedded?.['wp:featuredmedia']?.[0]?.source_url || undefined,
  };
};

// const mapImage = (image: any) => {
//   if (!image) return undefined;
//   return {
//     id: image.id,
//     createdAt: image.attributes.createdAt,
//     alternativeText: image.attributes.alternativeText,
//     caption: image.attributes.caption,
//     formats: image.attributes.formats,
//     hash: image.attributes.hash,
//     height: image.attributes.height,
//     name: image.attributes.name,
//     previewUrl: image.attributes.previewUrl,
//     provider: image.attributes.provider,
//     provider_metadata: image.attributes.provider_metadata,
//     size: image.attributes.size,
//     updatedAt: image.attributes.updatedAt,
//     url: image.attributes.url,
//     width: image.attributes.width,
//   };
// };

const mapCategory = (category: Category) => {
  if (!category) return undefined;
  return {
    id: category.id,
    name: category.name,
    slug: category.slug,
    description: category.description,
  };
};

// const mapAuthor = (author: any) => {
//   if (!author) return undefined;
//   return {
//     id: author.id,
//     createdAt: author.attributes.createdAt,
//     name: author.attributes.name,
//     updatedAt: author.attributes.updatedAt,
//     avatar: mapImage(author.attributes.avatar.data),
//   };
// };
